import React from "react"
import { graphql } from "gatsby"
import Nav from "../components/Nav"
import Hero from "../components/Hero"
import Contact from "../components/Contact"
import Footer from "../components/Footer"
import SEO from "../components/seo/SEO"
import NOTFOUND from "../images/page_not_found.svg"

const About = ({ data }) => {
  return (
    <>
      <SEO />
      <div className="gradient">
        <Nav />
        <Hero
          heading={"Oups, vous n'êtes pas sur la bonne page !"}
          subText={"Quelque part sur internet..."}
          subHeading={"Ce n'est pas très grave, ça arrive même aux meilleurs"}
          buttonText="Revenir aux cours"
          full={true}
          handleClick={() => {
            if (typeof window !== "undefined") {
              window.history.back()
            }
          }}
        />
      </div>

      <section className="bg-white py-0 container mx-auto m-0" id="legal">
        <div className="flex flex-wrap items-center justify-center">
          <div className="w-full sm:w-1/2 p-6">
            <img
              className="w-full sm:h-64 mx-auto"
              src={NOTFOUND}
              alt="404 illustration"
            />
          </div>
          <div className="w-5/6 sm:w-1/2 p-6">
            <h3 className="text-3xl text-blue font-bold leading-none mb-3">
              Si vous avez un peu de temps
            </h3>
            <p className="text-gray-600 mb-8">
              Si vous avez un peu de temps, n'hésitez pas nous faire des retours
              ou nous donner des idées d'améliorations à l'addresse suivante :{" "}
              <span className="font-bold">kristen@nerdy-bear.com</span>
            </p>
            <p className="text-gray-600 mb-8">
              Votre avis est important, c'est avant tout vous qui utilisez la
              plateforme, n'hésitez donc pas à nous décrocher un petit mail ! :)
            </p>
          </div>
        </div>
      </section>

      <Contact />
      <Footer image={data.footerImage.childImageSharp.fluid} />
    </>
  )
}

export const query = graphql`
  query NoPageQuery {
    site {
      siteMetadata {
        aboutDescription
        aboutMain
        aboutSubMain
      }
    }
    footerImage: file(relativePath: { eq: "logo.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default About
